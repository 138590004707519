import React, { useState } from "react";
import "./TableBody.css";
import Button from "../Button/Button";
import Delete from "../../asset/delete.svg";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { ColorRing } from "react-loader-spinner";

const TableBody = ({ path, order, toggleNotification, tableData, loading }) => {
  const [status, setStatus] = useState(true);

  return (
    <>
      {order === "Customers" ? (
        <section className="table_body">
          <div className="table_body_header">
            {[
              "S/n",
              "id",
              "User",
              "created on",
              "email",
              "status",
              "action",
            ].map((item, index) => (
              <div
                key={index}
                className={`table_body_header_item_${1 + index}`}
              >
                <p className="table_body_header_text">{item}</p>
              </div>
            ))}
          </div>
          <>
            {loading ? (
              <div className="list_loader">
                <ColorRing
                  visible={true}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["black", "black", "black", "black", "black"]}
                />
              </div>
            ) : (
              tableData?.map((item, index) => {
                const { userName, createdAt, phone, _id, email, country } =
                  item;
                return (
                  <div key={index} className="table_body_body">
                    <div className={`table_body_header_item_1`}>
                      <p className="food_item_paragraphs">{index + 1}</p>
                    </div>
                    <Link
                      to={`/${path}/${_id}`}
                      className={`table_body_header_item_2`}
                    >
                      <p className="paginators_numbers">{_id.slice(0, 8)}</p>
                    </Link>
                    <div className={`table_body_header_item_3`}>
                      <p className="food_item_paragraphs">{userName}</p>
                    </div>
                    <div className={`table_body_header_item_4`}>
                      <p className="food_item_paragraphs">
                        {moment(createdAt).format("MMMM Do YYYY, h:mm")}
                      </p>
                    </div>

                    <div className={`table_body_header_item_6`}>
                      <p className="food_item_paragraphs">{email}</p>
                    </div>

                    <div className={`table_body_header_item_8`}>
                      <Button
                        background={status ? "#EDFFF9" : "#FFF3E7"}
                        title={status ? "Active" : "Inactive"}
                        color={status ? "#27AE61" : "var(--other-color)"}
                        width={83}
                        height={30}
                      />
                    </div>
                    <div className={`table_body_header_item_9`}>
                      <img
                        onClick={() => toggleNotification(_id)}
                        src={Delete}
                        alt=""
                      />
                    </div>
                  </div>
                );
              })
            )}
          </>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default TableBody;
