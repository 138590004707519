import React, { useEffect } from "react";
import "./CustomerDetails.css";
import { CreateFoodHeader } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../context/context";

const CustomerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getUserDetails, userDetails } = useGlobalContext();
  const {
    userName,
    country,
    city,
    phone,
    email,
    address,
    gender,
    maritalstatus,
  } = userDetails;

  useEffect(() => {
    getUserDetails(id);
  }, []);

  return (
    <section className="customer_details">
      <CreateFoodHeader
        title={"User Details"}
        btn={true}
        navigate={() => navigate(`/update-client/${id}`)}
        navigateBack={() => navigate("/users")}
      />

      <form className="add_food_item_form">
        <div className="add_food_item_form_item_three">
          <p className="add_food_item_form_labels">Email</p>
          <input
            type="text"
            className="food_item_inputs"
            defaultValue={email}
          />
        </div>
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">User Name</p>
          <input
            type="text"
            className="food_item_inputs"
            defaultValue={userName}
          />
        </div>

        <div className="add_food_item_form_item_nine">
          <p className="add_food_item_form_labels">Password</p>
          <input
            placeholder="Passwords"
            type="password"
            className="food_item_inputs"
            defaultValue={12345678}
          />
        </div>
      </form>
    </section>
  );
};

export default CustomerDetails;
