import customers from "../asset/profile-2user copy.svg";
import user from "../asset/user.svg";
import settings from "../asset/setting.svg";
import contact from "../asset/contact.svg";
import about from "../asset/about.svg";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { RiTerminalWindowFill } from "react-icons/ri";

export const navItems = [
  {
    category: "User Management",
    details: [
      {
        name: "Users",
        img: customers,
        path: "users",
      },
    ],
  },
  {
    category: "Settings",
    details: [
      {
        name: "Logout",
        img: settings,
        path: "auth/login",
      },
    ],
  },
];

export const pages = [
  {
    name: "Contact Us",
    img: contact,
    path: "contact_us",
  },
  {
    name: "About Us",
    img: about,
    path: "about_us",
  },
  {
    name: "Privacy policy",
    img: <MdOutlinePrivacyTip color="var(--secondary-color)" size={28} />,
    path: "privacy_policy",
  },
  {
    name: "Terms of service",
    img: <RiTerminalWindowFill color="var(--secondary-color)" size={28} />,
    path: "terms",
  },
];
